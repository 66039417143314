import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Carousel from "../components/carousel"
import ContactForm from "../components/contact-form"
import ImageRow from "../components/image-row"
import Break from "../components/break"
import Titles from "../components/titles"
import SEO from "../components/seo"

const IndexPage = () => (<div>
  <Layout>
    <SEO title="Services" />
    <div style={{ }}>
      <h1 style={{marginBottom:"60px", fontSize:"36px",marginTop:"80px", textAlign:"center"}}>About</h1>
      <ContactForm><div className={"titles inline"}><h3>Contact Frances</h3></div></ContactForm> 
      <ImageRow rowType={"largeRow"} images={[{type:"text", text: <div style={{padding:"40px"}}>
      <p>Frances Keevil has extensive experience working in the Arts for over 30 years.<br /><br />
      Her initial involvement in art began when she discovered a passion for picture framing and art display when studying art presentation in galleries overseas. Following this, on her return, she established a highly successful specialised, custom picture framing business, Ace Frames in Rose Bay which she sold after 8 very successful years.<br /><br /> 
      As her interest in art and design evolved, Frances established a new career as Frances Keevil Art and Design. At times, collaborating with other art consultants and designers to provide art in many large corporate businesses. Including St George Private Hospital, Prince of Whales Private Hospital  and Marsh.<br /><br />
       During this time Frances developed relationship with artists and  set up pop-up art exhibitions and represented artists’ work in numerous Art Fairs. <br /><br />
      14 years ago Frances launched the Frances Keevil Gallery in Bay Village, Double Bay which became a respected fixture in Sydney’s art landscape for 12 years.<br /><br />
      With 2020 being a memorable year that none of us foresaw, with redevelopments locally in Double Bay, and with pandemics beyond, and with the future of gallery spaces still unpredictable, Frances has re-opened on-line.
      <br /><br />In 2021, Frances Keevil continues with <br /><br />
    <li>an on-line gallery, showcasing a selection of established and exciting new artists
    </li><li> curated exhibitions
    </li><li> art consulting (domestic and corporate) 
    </li><li> restoration services
    </li><li> custom designed framing
    </li><li> a series of surprise pop-up events featuring, art, music and more.    
  </li> </p>
      </div>, span:"12"}] } />
      <Carousel images={["adam-void-5.jpg","sue-01.jpg","sally-01.jpg"]}/>
    </div>

  </Layout>

</div>
)

export default IndexPage
